import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { UserAuthorizationRoleEnum } from '@equips/entities-schema';
import { getLocationsByOrganizationId } from '../../graphql/queries/locationGraphQLQueries';
import { useAuth } from '../auth/AuthContext';
import { queryUsers } from '../../graphql/queries/userQueries';

export const useLocations = (
  organizationId: string | string[],
  { includeActiveEquipmentCount = false, includeLocationsNotOnUser = false, excludeSublocations = false, q = undefined } = {},
) => {
  const { auth, admins, userCan } = useAuth();

  const { data: userData } = useQuery(['useLocationsUserQuery', auth?.userId], () => queryUsers({ userId: auth?.userId }), {
    enabled: !!auth?.userId && !userCan([...admins, UserAuthorizationRoleEnum.EmailOnlyAuth]),
    select: (data) => data?.data,
  });

  const userLocationIds = userData?.users?.data?.[0]?.metadata?.locationIds || [];

  const {
    data,
    isLoading,
    fetchStatus,
    refetch: fetch,
  } = useQuery(
    ['useLocationsLocations', organizationId, q],
    () => getLocationsByOrganizationId({ organizationId, excludeSublocations, includeActiveEquipmentCount, q }),
    {
      enabled: !!organizationId,
      select: (data) => data?.data,
    },
  );

  const unFilteredLocations = data?.locations?.data || [];

  const locations = useMemo(
    () =>
      includeLocationsNotOnUser || userCan([...admins, UserAuthorizationRoleEnum.EmailOnlyAuth])
        ? unFilteredLocations
        : unFilteredLocations.filter((location) => userLocationIds.includes(location?.metadata?.locationId || '')),
    [unFilteredLocations, userLocationIds], // eslint-disable-line
  );

  const defaultLocation = useMemo(() => {
    if (locations.length === 1 && locations[0]) {
      return locations[0];
    } else {
      return locations.find((l) => l?.locationId === auth?.locationId) ?? undefined;
    }
  }, [locations, auth]);

  return { locations, loading: isLoading && fetchStatus !== 'idle', fetch, defaultLocation };
};
